import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EmployDetailsPhoto = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmitPhoto = async () => {
    if (!file) {
      toast.error("Please add a file before submitting", { theme: "colored" });
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/ed/add-photo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("ID Photo uploaded successfully", { theme: "colored" });
      toast.success("Continue with Live Photo", { theme: "colored" });
      console.log("API Response:", response);
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Error uploading ID Photo", { theme: "colored" });
    } finally {
      navigate("/dashboard");
      setLoading(false);
    }
  };

  return (
    <div className="p-4 w-full mx-auto bg-white rounded-xl shadow-md">
      <ToastContainer />
      <div>
        <div className="p-4">
          <label htmlFor="idPhoto" className="block font-regular text-black">
            ID Card Photo
          </label>
          <input
            onChange={handleFileChange}
            name="idPhoto"
            type="file"
            id="idPhoto"
            className="p-2 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
          <button
            className={`w-full py-2 px-4 rounded-md text-white ${
              loading ? "bg-blue-400" : "bg-blue-600 hover:bg-blue-700"
            } shadow-lg mt-4`}
            onClick={handleSubmitPhoto}
            disabled={loading}
          >
            {loading ? "Sending..." : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployDetailsPhoto;
