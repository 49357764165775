import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import { Route, Routes } from "react-router-dom";
import Products from "./pages/Products";
import TandC from "./extras/TandC";
import DisclosurePolicy from "./extras/DisclosurePolicy";
import SecurityPolicy from "./extras/SecurityPolicy";
import FPracticesCode from "./extras/FPracticesCode";
import Responsible from "./extras/Responsible";
import ROI from "./extras/ROI";
import Refund from "./extras/Refund";
import Profile from "./pages/routes/Profile";
import Kyc from "./Dashboard/Kyc";
import Error from "./components/Error";
import EmployDetails from "./Dashboard/EmployDetails";
import GRPolicy from "./extras/GRPolicy";
import PrivacyPolicy from "./extras/PrivacyPolicy";
import Disclaimer from "./extras/Disclaimer";
import FaQ from "./extras/Faqpages";
import About from "./pages/About";
import Investment from "./pages/Investment";
import Test1 from "./extras/Test1";
import Financialdictionary from "./pages/Financialdictionary";
import FinancialDetails from "./pages/FinancialDetails";
import ForgotPassword from "./pages/forgotPassword";
import Contact from "./pages/Contact";
import RSS from "./pages/RSS";
import ElPage from "./pages/ElPage";
import Admin from "./components/Admin/Admin";
import AdminUser from "./components/Admin/AdminUser";
import AdminUserId from "./components/Admin/AdminUserId";
import AdminNavbar from "./components/Admin/AdminNavbar/AdminNavbar";
import Accessibility from "./pages/Accessibility";
import LoginApp from "./pages/LoginApp";
import RegisterApp from "./pages/RegisterApp";
import PrivacyPolicyApp from "./pages/PrivacyPolicyApp";
import Payment from "./components/Payments/Payment";
import NachSuccess from "./components/Nach/Success.jsx";
import NachFailure from "./components/Nach/Failure.jsx";
import ContractSuccess from "./components/Contract/Success.jsx";
import ContractFailure from "./components/Contract/Failure.jsx";
import Success from "./components/Success";
import Failure from "./components/Failure";
import AdminContacts from "./components/Admin/AdminUserMobile/AdminContacts";
import AdminMobileInfo from "./components/Admin/AdminUserMobile/AdminMobileInfo";
import AdminUserContactMedia from "./components/Admin/AdminUserMobile/AdminUserContactMedia";
import LivePhoto from "./Dashboard/LivePhoto";
import TransactionMain from "./components/Transaction/TransactionMain";
import TestLab from "./extras/TestLab";
import CourtCase from "./Dashboard/CourtCase";
import GetAdminContact from "./components/Admin/AdminContact/GetAdminContact";
import IndexPayment from "./components/Payments/IndexPayment";
import PayoutSuccessPage from "./components/Payout/Success.jsx";
import PayoutFailure from "./components/Payout/Failure.jsx";

function App() {
  return (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="header" Component={Header} />
      <Route path="login" Component={Login} />
      <Route path="signup" Component={Register} />
      <Route path="testlab" Component={TestLab} />
      <Route path="app">
        <Route path="login" Component={LoginApp} />
        <Route path="signup" Component={RegisterApp} />
        <Route path="privacy" Component={PrivacyPolicyApp} />
      </Route>
      <Route path="products" Component={Products} />
      <Route path="test1" Component={Test1} />
      <Route path="footer" Component={Footer} />
      <Route path="termsandconditions" Component={TandC} />
      <Route path="disclosurepolicy" Component={DisclosurePolicy} />
      <Route path="accessibility" Component={Accessibility} />
      <Route path="securitypolicy" Component={SecurityPolicy} />
      <Route path="fairpracticescode" Component={FPracticesCode} />
      <Route path="grievancepolicy" Component={GRPolicy} />
      <Route path="about" Component={About} />
      <Route path="privacypolicy" Component={PrivacyPolicy} />
      {/* <Route path="test1" Component={AdminContacts} /> */}
      <Route path="test2" Component={AdminContacts} />
      <Route path="disclaimer" Component={Disclaimer} />
      <Route path="responsible" Component={Responsible} />
      <Route path="roi" Component={ROI} />
      <Route path="refund" Component={Refund} />
      <Route path="contact" Component={Contact} />
      <Route path="faq" Component={FaQ} />
      <Route path="rss" Component={RSS} />
      <Route path="financial-dictionary" Component={Financialdictionary} />
      <Route path="financial-dictionary/:id" Component={FinancialDetails} />
      <Route path="investment" Component={Investment} />
      <Route path="forgot-password" Component={ForgotPassword} />
      <Route path="eligibility" Component={ElPage} />
      <Route path="success" Component={Success} />
      <Route path="failure" Component={Failure} />
      <Route path="nach">
        <Route path="success" Component={NachSuccess} />
        <Route path="failure" Component={NachFailure} />
        <Route path="cb" Component={NachFailure} />
      </Route>
      <Route path="contract">
        <Route path="success" Component={ContractSuccess} />
        <Route path="failure" Component={ContractFailure} />
      </Route>
      <Route path="payout">
        <Route path="success" Component={PayoutSuccessPage} />
        <Route path="failure" Component={PayoutFailure} />
      </Route>
      <Route path="transaction" Component={TransactionMain} />
      <Route path="payment" Component={IndexPayment} />
      <Route path="dashboard" Component={Dashboard}>
        <Route index Component={Profile} />
        <Route path="transaction" Component={TransactionMain} />
        <Route path="kyc" Component={Kyc} />
        <Route path="photo" Component={LivePhoto} />
        <Route path="legalcheck" Component={CourtCase} />
        <Route path="employmentdetails" Component={EmployDetails} />
      </Route>
      <Route path="admin" Component={AdminNavbar}>
        <Route index Component={Admin} />
        <Route path="profiles" Component={AdminUser} />
        <Route path="forms" Component={GetAdminContact} />
        <Route path="contact/" Component={AdminMobileInfo} />
        <Route path="contact/:id" Component={AdminUserContactMedia} />
        <Route path=":id" Component={AdminUserId} />
      </Route>
      <Route path="*" Component={Error} />
    </Routes>
  );
}

export default App;
