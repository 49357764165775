import React from "react";
import { NavLink } from "react-router-dom";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import logoIC from "../core/images/logo-noncopy.png";
import QRApp from "../core/images/QR.jpg";

const FooterMain = ({ changeView }) => {
  return (
    <section>
      <div className="p-10 bg-gray-950">
        <div id="wrapper">
          <div className="flex text-white gap-10 flex-col lg:flex-row">
            <div className="flex-1">
              <div className="gap-4 flex flex-col">
                <img src={logoIC} alt="Logo" className="w-[120px]" />
                <p className="text-white text-sm">
                  Thinking "Oh but I have a credit card, why do I need to use
                  PlentyCred?". Yeah, we can read minds! Well, we give you
                  multiple benefits over credit cards..
                </p>
              </div>
            </div>
            <div className="flex-1 bg-gray-900 p-5 rounded-lg">
              <div className="flex gap-4 flex-col">
                <h1 className="font-semibold">Info</h1>
                <div
                  onClick={() => {
                    changeView(0);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> Home
                </div>
                <div
                  onClick={() => {
                    changeView(2);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> About
                </div>
                <div
                  onClick={() => {
                    changeView(1);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                  Products
                </div>
                <div
                  onClick={() => {
                    changeView(4);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                  Payments
                </div>
                <div
                  onClick={() => {
                    changeView(6);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                  Eligibility
                </div>
                <div
                  onClick={() => {
                    changeView(9);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                  Contact
                </div>
                <div
                  onClick={() => {
                    changeView(8);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                  Investment
                </div>
                <div
                  onClick={() => {
                    changeView(23);
                  }}
                  className="transition hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> Why
                  Us
                </div>
              </div>
            </div>
            <div className="flex-[1.2]">
              <div className="flex-1 bg-gray-900 p-5 rounded-lg">
                <div className="flex gap-4 flex-col">
                  <h1 className="font-semibold">Legal</h1>
                  <div
                    onClick={() => {
                      changeView(14);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Terms & Conditions
                  </div>
                  <div
                    onClick={() => {
                      changeView(15);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Privacy Policy
                  </div>
                  <div
                    onClick={() => {
                      changeView(12);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Disclaimer
                  </div>
                  <div
                    onClick={() => {
                      changeView(16);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Responsible Disclosure
                  </div>
                  <div
                    onClick={() => {
                      changeView(13);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Accessibility
                  </div>
                  <div
                    onClick={() => {
                      changeView(17);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Security Policy
                  </div>
                  <div
                    onClick={() => {
                      changeView(18);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Fair Practice Code
                  </div>
                  <div
                    onClick={() => {
                      changeView(19);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Grievance Policy
                  </div>
                  <div
                    onClick={() => {
                      changeView(20);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Responsible Lending
                  </div>
                  <div
                    onClick={() => {
                      changeView(21);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> ROI
                    Processing
                  </div>
                  <div
                    onClick={() => {
                      changeView(22);
                    }}
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
                    Refund and Cancellation
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex-1 bg-gray-900 p-5 rounded-lg">
                <div className="flex gap-4 flex-col">
                  <h1 className="font-semibold">Follow Us</h1>
                  <NavLink
                    to="https://www.facebook.com/plentycred"
                    className="transition text-blue-400 hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faFacebook} className="pr-2" />{" "}
                    Facebook
                  </NavLink>
                  <NavLink
                    to="https://www.instagram.com/plenty_cred/"
                    className="transition text-purple-400 hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faInstagram} className="pr-2" />{" "}
                    Instagram
                  </NavLink>
                  <NavLink
                    to="https://www.threads.net/@plenty_cred"
                    className="transition hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faThreads} className="pr-2" />{" "}
                    Threads
                  </NavLink>
                  <NavLink
                    to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
                    className="transition text-red-500 hover:text-gray-600"
                  >
                    <FontAwesomeIcon icon={faYoutube} className="pr-2" />{" "}
                    Youtube
                  </NavLink>
                  <br />

                  <p className="text-sm text-center">
                    Scan QR to get PlentyCred App
                  </p>
                  <div className="flex justify-center items-center flex-col gap-4">
                    <img src={QRApp} width={140} height={300} alt="" />
                  </div>

                  <h1 className="font-semibold text-center">
                    Download the App Now
                  </h1>

                  <NavLink
                    to="https://play.google.com/store/apps/details?id=com.application.plentycredapp"
                    className="transition flex flex-row gap-2 bg-white text-black p-2 rounded-lg hover:bg-slate-800 hover:text-white"
                  >
                    <IoLogoGooglePlaystore size={20} />{" "}
                    <span className="font-semibold text-sm">
                      Download on Play Store
                    </span>
                  </NavLink>
                  <NavLink
                    to={`${process.env.REACT_APP_API_KEY}/download-app`}
                    className="transition flex flex-row gap-2 bg-white text-black p-2 rounded-lg hover:bg-slate-800 hover:text-white"
                  >
                    <IoLogoGooglePlaystore size={20} />{" "}
                    <span className="font-semibold text-sm">
                      Direct Download
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div id="wrapper">
          <div className="flex flex-col lg:flex-row gap-4 justify-between gap-20px items-center">
            <div className="flex-[1]">
              <p className="font-semibold text-xl text-white">
                Made with 💖 in India
              </p>
            </div>
            <div className="flex-[1]">
              <p className="font-semibold text-sm text-white">
                Plentycred is a fully owned subsidiary of Arihant Economy
                Services Private Limited. It is a digital platform that helps
                customers borrow money for their needs at the click of a button.
                All rights reserved by Arahant Economy Services
                Private Limited. &copy; 2024
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterMain;
