"use client";

import React, { useState, useEffect } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";

const TransactionMain = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No authentication token found");
        }

        const response = await fetch(
          "https://plentycred.co.in/api/v2/paymentdata/transactions",
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch transactions");
        }

        const data = await response.json();
        setTransactions(data.payments);
        setLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Transaction ID copied to clipboard!");
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground"
      ></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-10">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-white">
        Transaction History
      </h1>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <div className="grid grid-cols-5 gap-4 font-semibold text-sm text-gray-600">
            <div>Transaction ID</div>
            <div>Date</div>
            <div>Amount</div>
            <div>Status</div>
            <div>Type</div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {transactions.map((transaction) => (
            <li
              key={transaction.id}
              className="px-6 py-4 hover:bg-gray-50 transition duration-150 ease-in-out"
            >
              <div className="grid grid-cols-5 gap-4 items-center">
                <div
                  className="text-sm font-medium text-gray-900 truncate flex items-center"
                  title={transaction.merchantTransactionId}
                >
                  {transaction.merchantTransactionId.slice(0, 10)}...
                  <button onClick={() => copyToClipboard(transaction.merchantTransactionId)} className="ml-2">
                    <ArrowUpIcon className="h-5 w-5 text-gray-500" />
                  </button>
                </div>
                <div className="text-sm text-gray-500">
                  {formatDate(transaction.createdAt)}
                </div>
                <div className="text-sm font-semibold text-gray-900">
                  ₹{parseFloat(transaction.amount).toFixed(2)}
                </div>
                <div
                  className={`text-sm ${
                    transaction.paymentComplete
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {transaction.paymentComplete ? "Completed" : "Failed"}
                </div>
                <div className="flex items-center">
                  {parseFloat(transaction.amount) >= 0 ? (
                    <ArrowUpIcon className="h-5 w-5 text-green-500 mr-1" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 text-red-500 mr-1" />
                  )}
                  <span className="text-sm text-gray-600">
                    {parseFloat(transaction.amount) >= 0 ? "Credit" : "Debit"}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground"
      ></div>
    </div>
  );
};

export default TransactionMain;
