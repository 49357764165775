import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";

const Payout = ({ userDetails }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [amount, setAmount] = useState("");
  const [repaymentDate, setRepaymentDate] = useState("");
  const navigate = useNavigate();

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  // Get the date 12 months from today
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 12);
  const maxDateFormatted = maxDate.toISOString().split("T")[0];

  const handlePayoutClick = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Token not found, please log in again.", {
          theme: "colored",
        });
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/payout/initiate`,
        {
          amount,
          repaymentDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Data for handlePayoutClick>>>>", response.data);
      toast.success("Payout successfully completed", { theme: "colored" });
      setShowPopup(false); // Close the popup after a successful request
    } catch (error) {
      toast.error("Error while posting your data in Payout", {
        theme: "colored",
      });
      console.error("Error handlePayoutClick data:", error);
      navigate("/payout/failure");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <button
        onClick={() => setShowPopup(true)}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center gap-2"
      >
        Make Payout <ArrowRightAltIcon />
      </button>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Enter Payout Details</h2>
            <div className="mb-4">
              <label className="block text-gray-700 mb-1">Amount</label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-1">Repayment Date</label>
              <input
                type="date"
                value={repaymentDate}
                onChange={(e) => setRepaymentDate(e.target.value)}
                min={today}
                max={maxDateFormatted}
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handlePayoutClick}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payout;
