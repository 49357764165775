import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AAUploadStatement from "./AAUploadStatement";

const CourtCase = () => {
  const [formData, setFormData] = useState({
    address: "",
    fatherName: "",
  });
  const [bankData, setBankData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/v2/aa/banks`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setBankData(response.data.data || []);
      } catch (error) {
        toast.error("Failed to fetch bank data.");
      }
    };
    fetchBanks();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectBank = (bank) => {
    setSelectedBank(bank);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.error("Token not found. Please login again.");
      return;
    }

    if (!selectedBank) {
      toast.error("Please select a bank.");
      return;
    }

    setIsLoading(true);

    try {
      const courtCaseResponse = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/courtCase/get-courtcase`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (courtCaseResponse.status === 200) {
        const initiateResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v2/aa/initiate`,
          {
            institutionId: selectedBank.id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Successfully completed!");
        window.location.href = initiateResponse.data.data.url;
      } else {
        toast.error("Failed to process court case.");
      }
    } catch (error) {
      toast.error(
        `Error: ${error.response?.data?.message || "An error occurred"}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const filteredBanks = bankData.filter((bank) =>
    bank.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-10">
      <div className="max-w-[600px] mt-[80px]"></div>
      <div className="max-w-[600px] m-auto mt-2 mb-32">
        <h1 className="font-semibold text-5xl text-white">
          Legal Check & Account Aggregator (Bank statement)
        </h1>
        <br />
        <div className="p-10 text-[#222] bg-white flex flex-col rounded-lg shadow-lg">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block mb-2">Address</label>
              <textarea
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="resize-none w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Father's Name</label>
              <input
                type="text"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Search for Bank</label>
              <input
                type="text"
                placeholder="Search by bank name"
                value={searchTerm}
                onChange={handleSearch}
                className="w-full p-2 border rounded mb-2"
              />
              <div className="border rounded max-h-40 overflow-auto">
                {filteredBanks.map((bank) => (
                  <div
                    key={bank.id}
                    className={`p-2 cursor-pointer hover:bg-gray-200 ${
                      selectedBank?.id === bank.id ? "bg-gray-300" : ""
                    }`}
                    onClick={() => handleSelectBank(bank)}
                  >
                    <p className="font-bold">{bank.name}</p>
                    <p className="text-sm">{bank.inst_type}</p>
                    <p className="text-sm">Vendors: {bank.aa_vendors}</p>
                    <p className="text-sm">FIP ID: {bank.fip_id}</p>
                  </div>
                ))}
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 w-full text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Verify
            </button>
          </form>
        </div>
      </div>

      <div>
        <AAUploadStatement />
      </div>

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold">Please wait...</p>
          </div>
        </div>
      )}

      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground"
      ></div>
    </div>
  );
};

export default CourtCase;
