import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
const RSS = () => {
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/rss/rss-news`
        );

        const responseData = response.data["data"];
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(responseData, "text/xml");
        const items = xmlDoc.querySelectorAll("item");

        const parsedData = Array.from(items).map((item) => {
          console.log(JSON.stringify(item));

          const titleNode = item.querySelector("title");
          const titleText = titleNode ? titleNode.textContent : "";

          const linkNode = item.querySelector("link");
          const linkText = linkNode ? linkNode.textContent : "";

          const descriptionNode = item.querySelector("description");
          const descriptionText = descriptionNode
            ? descriptionNode.textContent
            : "";

          const mediaContentNode = item.querySelector("content");
          const imageUrl = mediaContentNode
            ? mediaContentNode.getAttribute("url")
            : "";

          return {
            link: linkText.trim(),
            title: titleText.trim(),
            description: descriptionText.trim(),
            imageUrl: imageUrl.trim()
          };
        });

        console.log("Parsed Data>>>", parsedData);

        setData(parsedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {location.pathname === "/rss" ? (
        <>
          <Header />
        </>
      ) : (
        <></>
      )}
      <br />
      <br />
      <br />
      <br />
      <div className=" ">
        <div id="rtlanimation" className="p-6 ">
          <div className="p-7">
            <h1 className="text-3xl xl:text-5xl  text-white font-medium">
              Blogs
            </h1>
            <p className="text-white p-1">
              At PlentyCred, we understand the specific financial problems of
              working professionals and strive to solve their unique needs.
            </p>
          </div>
        </div>
        <div id="wrapper">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {data.map((item, index) => {
              // Define the maximum number of characters for description
              const maxDescriptionLength = 120; // Change this value as needed
              const minLength = 90;
              // Trim the description text if it exceeds the maximum length
              const trimmedDescription =
                item.description.length > maxDescriptionLength
                  ? item.description.substring(0, maxDescriptionLength) + "..."
                  : item.description;

              const truncatedTitle =
                item.title.length > minLength
                  ? item.title.substring(0, minLength) + "..."
                  : item.title;

              return (
                <NavLink
                  to={item.url}
                  className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-2xl "
                >
                  <div className="">
                    <div className="">
                      <img
                        className=" rounded-t-lg"
                        src={item.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="p-5 flex flex-col gap-5">
                      <h5
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="font-semibold  text-xl text-[#0083ec]"
                      >
                        {truncatedTitle}
                      </h5>
                      <p className="font-semilight text-[14px]">
                        {trimmedDescription}
                      </p>
                    </div>
                  </div>
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RSS;
