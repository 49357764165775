import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminUserLivePhoto = ({ userId }) => {
  const [locationId, setLocationId] = useState(null);
  // const [imageURL, setImageURL] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Fetch location ID from API
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_KEY}/v1/admin/live-photo`,
  //         { id: userId },
  //         {
  //           headers: {
  //             "X-admin-auth": "1234567890",
  //           },
  //         }
  //       );

  //       console.log("Response of live Photo>>>" , response.data)
  //       const locationId = response.data.data.LiveLocation;

  //       // Set location ID in state
  //       setLocationId(locationId);
  //       console.log(locationId);
  //       // Use location ID to retrieve image from Firebase Storage
  //       const storageRef = firebase.storage().ref();
  //       const imageRef = storageRef.child(`${locationId}`);
  //       const url = await imageRef.getDownloadURL();

  //       // Set image URL in state
  //       setImageURL(url);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []); // Empty dependency array to run only once when component mounts

  return (
    <div>
      <h1>User</h1>
      {/* Firebase image need to be added */}
      {/* {imageURL && <img width={300} height={300} src={imageURL} alt="Live Photo" />} */}
    </div>
  );
};

export default AdminUserLivePhoto;
