import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AAUploadStatement = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('stateMentImage', file);

    setLoading(true);
    const token = localStorage.getItem("token")
    try {
      const response = await axios.post(
       `${process.env.REACT_APP_API_KEY}/v2/aa/statement/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          },
        }
      );
      toast.success('Statement uploaded successfully');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Upload failed');
    } finally {
      setLoading(false);
      setFile(null);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-gray-600 mb-6">
      Kindly upload you bank statement if you are unable to find your bank in Account Aggregator enviornment.
      </h2>
      
      <div className="space-y-4">
        <input
          type="file"
          onChange={handleFileChange}
          className="w-full border rounded-lg p-2"
          accept=".pdf"
          disabled={loading}
        />
        
        <button
          onClick={handleUpload}
          disabled={loading}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400"
        >
          {loading ? 'Uploading...' : 'Upload Statement'}
        </button>
      </div>
    </div>
  );
};

export default AAUploadStatement;