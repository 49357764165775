"use client";

import React, { useState } from "react";
import axios from "axios";
import { IoLockClosed } from "react-icons/io5";
import { ArrowRightIcon, X } from "lucide-react";
import { toast } from "react-toastify";

const AutoPay = ({ isLocked }) => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleAutoPayClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/upi-autopay/initiate-upi-autopay`,
        {},
        { headers }
      );
      setIframeSrc(response.data.result.data);
      setIsOpen(true);
      toast.success("AutoPay initiated", { theme: "colored" });
    } catch (error) {
      console.error("Error initiating AutoPay:", error);
      toast.error("Error initiating AutoPay", { theme: "colored" });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIframeSrc("");
  };

  return isLocked ? (
    <div className="transition flex flex-row gap-2 bg-red-500 hover:bg-red-700 text-white justify-center items-center font-semibold py-2 px-4 rounded-xl w-full mt-5">
      <div>Auto Pay</div>
      <div>
        <IoLockClosed size={20} />
      </div>
    </div>
  ) : (
    <div>
      <button
        className="transition bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl w-full mt-5"
        onClick={handleAutoPayClick}
      >
        Auto Pay <ArrowRightIcon className="ml-2 h-4 w-4" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-[800px] h-[600px] bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="absolute top-0 right-0 m-4">
              <button
                className="transition bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl"
                onClick={handleClose}
                aria-label="Close"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
            <div className="w-full h-full pt-12">
              <iframe
                src={iframeSrc}
                title="Payment"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoPay;
